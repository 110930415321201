<template>
    <div class="service-locations">
        <h1>Locais de atendimento</h1>
        <div class="locations">
            <div class="item" v-for="location in servicelocationsList" :key="location.id">
                <div class="flex align-items-center gap-14">
                    <img class="p-avatar" :src="getLocationAvatar (location.pictureUrl)" alt="" :style="{ width: '40px', height: '40px' }" />
                    <div class="flex flex-column">
                        <p class="name-location">{{location.name}}</p>
                        <p class="address">{{location.address}}, Nº{{ location.number }} - {{ location.neighborhood}}, {{ location.city }}, {{ location.state }} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import imageDefault from "../../../../assets/icons/upload-default.png";

export default {
    props: ["servicelocationsList"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {

        const getLocationAvatar = (pictureUrl) => {
            if (pictureUrl) {
                return process.env.VUE_APP_BUCKET_URL + '/' + pictureUrl;
            }

            return imageDefault
        }


        return {
            getLocationAvatar,
        }
    },
};
</script>


<style lang="scss" scoped>
.service-locations {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background: white;
    margin-top: -4px;
    border-radius: 0 0 8px 8px;
    padding: 12px;
    padding-top: 16px;
    z-index: 1;
    gap: 8px;
    .locations{
        display: flex;
        flex-direction: column;
        gap: 8px;
        .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            background: #FFFFFF;
            box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
            border-radius: 8px;
        }
    }
    .name-location{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4F4F4F;
    }
    .address{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #BDBDBD;
    }
    .location{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #828282;

        .item {
            .p-avatar {
                width: 40px;
                height: 40px;
                border-radius: 8px;
            }
        }
    }
}
.gap-4{
    gap: 4px;
}

h1{
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #BDBDBD;
}
</style>