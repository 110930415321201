<template>
  <div class="bg-modal" v-if="visible">
    <Dialog class="modal" :style="{
      width: '462px',
      borderRadius: '8px',
    }" :closable="false" :visible="visible" @hide="close" @update:visible="onUpdate">

      <template #header>
        <h5 class="m-auto">Adicionar Parceiro</h5>
      </template>

      <div class="content">

        <AutoComplete 
          ref="suggestionRef"
          v-model="textValue"   
          class="w-full"
          :suggestions="listSuggestions" 
          @complete="completeItem($event)"
          field="name"
          placeholder="Buscar"
          @item-select="showPartnerInfo($event)" 
          forceSelection 
        />

        <SelectedPartner v-if="dataPartner" :data="dataPartner"/>

        <div class="info-container" v-if="!dataPartner">
          <div class="tittle">Você também pode adicionar uma parceria compartilhando o link de convite abaixo:</div>
          <div class="link cursor-pointer mt-8">http://ommed.com/KJa78ajk21n</div>

          <div class="flex justify-content-center align-items-center gap-24 mt-14">
            <img class="cursor-pointer" src="../../../../assets/icons/copy.png" alt="" />
            <img class="cursor-pointer" src="../../../../assets/icons/wpp.png" alt="" />
            <img class="cursor-pointer" src="../../../../assets/icons/email.png" alt="" />
          </div>
        </div>

      </div>

      <template #footer>
        <div class="buttons" :class="loadingSubmit && 'cursor-not-allowed'">
          
          <Button class="cancel" label="Cancelar" @click="close" :disabled="loadingSubmit"/>
          <Button class="submit" 
           :disabled="!hasItemSelected || loadingSubmit"  
           @click="addPartnership(dataPartner.professionalid)"
           >
            Confirmar  <i class="pi pi-spin pi-spinner" v-if="loadingSubmit" />
          </Button>


        </div>
      </template>

    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted, nextTick } from "vue";
import axios from 'axios';
import AutoComplete from 'primevue/autocomplete';
import {
  SearchProfessionalsRequest,
  GetPartnerRequest,
  AddPartnershipRequest,
} from "../../../core/grpc/generated/professionalProfile_grpc_web_pb";
import professionalProfileService from "../../../core/services/professionalProfileService";
import SelectedPartner from "./SelectedPartner.vue";


const props = defineProps(["visible"]);
const emit = defineEmits(["update:visible", "addPartnerSuccess"]);

const suggestionRef = ref();

const textValue = ref("");
const hasItemSelected = ref(false);
const dataPartner = ref();
const listSuggestions = ref([]);
const loadingSubmit = ref(false);

const close = () => {
  textValue.value = "";
  loadingSubmit.value = false;
  dataPartner.value = null;
  listSuggestions.value = [];
  hasItemSelected.value = false;
  emit("update:visible", false)
}

const searchProfessionals = async (data) => {
  try {
      const arr = [];
      let req = new SearchProfessionalsRequest();
      req.setName(data.name);

      let res = await professionalProfileService.searchProfessionals(req);
      // console.log(res);

      if (res.success) {
          const professionals = res.professionalsList;
          console.log(professionals);

          await professionals.forEach(item => {
              arr.push({
                  name: item.name,
                  professionalid: item.professionalid
              })
          })
      } else {
        console.log('Request failed');
      }
      listSuggestions.value = arr
  } catch (error) {
      console.log(error)
  }
}


const showPartnerInfo = async (selectedItem) => {
  try {

    const professionalId = selectedItem.value.professionalid;

    const partnerInfo = await getPartner(professionalId);

    if (partnerInfo) {
      console.log('Informações do parceiro:', partnerInfo);
      hasItemSelected.value = true;
    } else {
      console.log('Falha ao obter informações do parceiro');
    }
  } catch (error) {
    console.log(error);
  } 
};

const getPartner = async (professionalId) => {
  try {
    const req = new GetPartnerRequest()
    req.setProfessionalid(professionalId);

    const res = await professionalProfileService.getPartner(req);

    const { success, professional  } = res;
    // console.log(res)

    if (success && professional) {
      dataPartner.value = professional;

      return success;

    } else {
      console.log('Falha ao obter informações do getParceiro');
    }

  } catch (error) {
      console.log(error)
  }
};

const addPartnership = async (professionalId) => {
  loadingSubmit.value = true
  try {
    const req = new AddPartnershipRequest()
    req.setProfessionalid(professionalId);

    const res = await professionalProfileService.addPartnership(req);

    const { success } = res;
    console.log(res)
    
    if ( success ) {
      close();
      emit("addPartnerSuccess");

      return success;

    } else {
      console.log('Falha add Parceiro');
    }
  } catch (error) {
    console.log(error)
    return null;
    
  } finally {
    loadingSubmit.value = false;
  }
}; 

const completeItem = event => {
  const obj = {
    name: event.query,
  }
  searchProfessionals(obj)
}

const onUpdate = (val) => {
  (!val) && emit("update:visible", val);
}

watch( () => props.name, (newValue, oldValue) => {
  if(newValue !== textValue.value){
    textValue.value = newValue
  }
});

function closeToESC (e) {
  if (e.key === "Escape") {
    close();
  }
}

onUnmounted(() => {
  document.removeEventListener("keydown", closeToESC)
});

onMounted(async () => {
  document.addEventListener("keydown", closeToESC)
  if(props.name){
    textValue.value = props.name
  }

  await nextTick();
  suggestionRef.value.$el.querySelector('input').focus();
});
</script>

<style lang="scss" scoped>
.bg-modal {
  width: 120vw;
  height: 120vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // z-index: 1000;
  background: rgb(250, 250, 250, .05);
  mask: radial-gradient(circle, rgba(0, 0, 0) 100%);
  -webkit-mask: radial-gradient(circle, rgba(0, 0, 0) 100%);
  backdrop-filter: blur(6px);
}

.info-container {
  display: flex;
  flex-direction: column;;
  align-items: center;
  gap: 10px;
  background: #FAFAFA;
  padding: 14px;
  border-radius: 20px;
  min-height: 100%;

  .tittle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #828282;
  }
  .link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
  }
}

.p-autocomplete-panel {
  border-radius: 100px;
}

.p-button {
  height: 40px;
}
.content {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .message-error {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 31px;
      white-space: nowrap;
      color: #EB5757;
    }
  }
  .p-dialog-header {
    span {
      font-family: 'Roboto' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 18px !important;
      line-height: 21px !important;
      color: #828282 !important;
    }
  }
}

.buttons{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
}

// ::v-deep(.p-dialog) {
//   z-index: 2300 !important;
// };

::v-deep(input) {
  height: 40px;
  border-radius: 8px;
  padding: 0 19px;
  color: #828282;
  border: 1px solid bdbdbd;
  outline: none;

  &::-webkit-input-placeholder {
    color: #bdbdbd;
  }

  &:focus {
    border-color: #ff6a33;
  }
};

:deep() {
  .cancel {
    background: #FAFAFA;
    border-color: #FAFAFA;
    box-shadow: none;

    color: #BDBDBD;
    border-radius: 128.571px;

    &:disabled {
      background: #F5F5F5 !important;
      border-color: #F5F5F5;
      box-shadow: none;
      color: #BDBDBD !important;
      border-radius: 128.571px;
      gap: 8px;
      min-width: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed !important;
    }

    &:hover {
      background: #FAFAFA;
      border-color: #FAFAFA;
      box-shadow: none;
      color: #BDBDBD;
      text-shadow: #BDBDBD 0 0 0.1rem;
    }
  }
};

::v-deep(.submit) {
  background: #FAFAFA !important;
  border-color: #FAFAFA;
  box-shadow: none;

  color: #828282;
  border-radius: 128.571px;
  gap: 8px;
  min-width: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    background: #F5F5F5 !important;
    border-color: #F5F5F5;
    box-shadow: none;
    color: #BDBDBD !important;
    border-radius: 128.571px;
    gap: 8px;
    min-width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed !important;
    
  }

  &:hover {
    background: #FAFAFA !important;
    border-color: #FAFAFA;
    box-shadow: none;
    color: #828282 !important;
    text-shadow: #828282 0 0 0.1rem;
    
  }
};
</style>
