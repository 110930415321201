<template>
    <div class="bg-modal" v-if="visible">
        <Dialog 
            class="modal" 
            :style="{
                width: '320px',
                borderRadius: '8px',
            }" 
            :visible="visible" 
            :closable="false"
            @hide="close" 
            @update:visible="onUpdate"
        >
            <template #header>
                <h5 class="m-auto">Remover parceiro</h5>
            </template>

            <div class="content">
                <p> Tem certeza que deseja remover este parceiro?</p>   
                <div class="buttons" :class="loadingSubmit && 'cursor-not-allowed'">
                    <Button class="cancel" label="Cancelar" @click="close" :disabled="loadingSubmit"/>
                    <Button class="remover" @click="deletePartnership" :disabled="loadingSubmit">
                        Confirmar  <i class="pi pi-spin pi-spinner" v-if="loadingSubmit" />
                    </Button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { ref } from "vue";
import axios from 'axios';
import professionalProfileService from "../../../core/services/professionalProfileService";
import  { DeletePartnershipRequest } from "../../../core/grpc/generated/professionalProfile_grpc_web_pb"

export default {
    props: ["visible", "obj"],
    emits: ["update:visible", "closeall", "successfull"],
    setup(props, { emit }) {

        const loadingSubmit = ref(false);

        const deletePartnership = async (obj) => {
            try {
                loadingSubmit.value = true
                const req = new DeletePartnershipRequest()
                req.setPartnershipid(props.obj.partnershipid);
                const res = await professionalProfileService.deletePartnership(req);

                const { success } = res
                console.log(res);

                if (success) {
                    emit("successfull", true);                
                }else{
                    
                }
                
            } catch (error) {
                console.log(error);
            }
            loadingSubmit.value = false;
        };
    
        const close = () => emit("update:visible", false)
        
        const onUpdate = val => (!val) && emit("update:visible", val);

        const CloseAll = () => {
            emit("update:visible", false);
            // emit("closeall", true);
        }


        return {
            deletePartnership,
            close,
            onUpdate,
            CloseAll,
            loadingSubmit,

        };
    },
};
</script>

<style lang="scss" scoped>

.bg-modal {
  width: 120vw;
  height: 120vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background: rgb(250, 250, 250, .05);
  mask: radial-gradient(circle, rgba(0, 0, 0) 100%);
  -webkit-mask: radial-gradient(circle, rgba(0, 0, 0) 100%);
  backdrop-filter: blur(6px);
}
.content {
    margin-top: 1.5rem;    
    margin-bottom: -10px;
    p{
        max-width: 200px;
        text-align: center;
        margin: 0 auto;
        color: #828282;
    }
    .buttons{
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 24px;
    }
}
::v-deep(.cancel) {
  background: #FAFAFA !important;
  border-color: #FAFAFA;
  box-shadow: none;

  color: #BDBDBD;
  border-radius: 128.571px;

  &:hover {
    background: #FAFAFA !important;
    border-color: #FAFAFA;
    box-shadow: none;
    color: #BDBDBD !important;
    text-shadow: #BDBDBD 0 0 0.1rem;
  }
}

::v-deep(.remover) {
  background: #FAFAFA !important;
  border-color: #FAFAFA;
  box-shadow: none;

  color: #828282;
  border-radius: 128.571px;
  gap: 8px;
  min-width: 104px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: #F5F5F5 !important;
    border-color: #F5F5F5;
    box-shadow: none;
    color: #BDBDBD !important;
    border-radius: 128.571px;
    gap: 8px;
    min-width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed !important;
  }
  
  &:hover {
    background: #FAFAFA !important;
    border-color: #FAFAFA !important;
    color: #828282 !important;
    text-shadow: #828282 0 0 0.1rem;
    
  }
}

</style>
