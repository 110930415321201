<template>
    <div class="container-profile">

      <img class="avatar" :style="{ border: '1px dashed rgb(189, 189, 189, .6)' }"
        src="../../../../assets/icons/upload-default.png" alt="" v-if="!data.pictureurl" />
      <img class="avatar" :src="getAvatar" alt="" v-else />

      <div class="infos">
        <p class="name">{{ data.firstname }} {{ data.lastname }}</p>
        <p class="council" v-if="data.councilprofessionalsList && data.councilprofessionalsList.length > 0"> {{ data.councilprofessionalsList[0].council }} {{ data.councilprofessionalsList[0].number}} - {{ data.councilprofessionalsList[0].state }}  </p>
      </div>
    </div>
  </template>
  
  <script>
  import { computed, ref } from "vue";
  
  export default {
    props: ["data"],
    setup(props, ctx) {
      
      const getAvatar = computed(() => {
        return process.env.VUE_APP_BUCKET_URL + '/' + props.data.pictureurl
      });
  
      return {
        getAvatar,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
    .container-profile {
    display: flex;
    align-items: center;
    gap: 12px;
    background: #FAFAFA;
    padding: 14px;
    border-radius: 12px;
    min-height: 80px;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);

    .avatar {
      width: 52px;
      height: 52px;
      border-radius: 14px;
      margin: 0;
      padding: 0;
    }

    .infos {
      .name {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 31px;
      }

      .council {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #BDBDBD;
      }
    }
  }

  </style>
  