<template>
    <div class="biography">
        <h1> Biografia </h1>
        <div class="desc">
            <p> {{ obj.biography }} </p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";


export default {
  props: ["obj"],
  components: {},
  setup(props, ctx) {

    return {
      
    };
  },
};

</script>


<style lang="scss" scoped>
.biography {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background: white;
    margin-top: -4px;
    border-radius: 0 0 8px 8px;
    padding: 12px;
    padding-bottom: 0px;
    padding-top: 16px;
    z-index: 1;
    gap: 8px;

    .desc {
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
        border-radius: 8px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #828282;
        padding: 16px 12px;

        p {
            max-width: 100%;
            overflow: hidden; // Removendo barra de rolagem
            text-overflow: ellipsis; // Adicionando "..." ao final
            display: -webkit-box;
            -webkit-line-clamp: 2; // Quantidade de linhas
            -webkit-box-orient: vertical;
        }

    }
}

h1 {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #BDBDBD;
}
</style>