<template>
  <div class="button-delete">
    <div class="flex cursor-pointer align-items-center gap-6 edit-data" 
        v-tooltip.top="'Desvincular'"
        @click="openDialog">
        Desvincular
        <img src="../../../../assets/icons/Close.svg" />
    </div>
  </div>

  <DialogDeletePartner
    v-model:visible="visibleDelete"
    :obj="obj" 
    @successfull="deleteSuccessfull"
  />

</template>
  
<script>
import { ref, watch } from "vue";
import DialogDeletePartner from "../../Dialogs/DialogDeletePartner/DialogDeletePartner.vue";

export default {
  props: ["obj"],
    emits: ["successfull", "reload"],
    components: {
      DialogDeletePartner
    },
  setup(props, { emit }) {
    const visibleDelete = ref(false);

    const deleteSuccessfull = path => {
            emit("successfull", true);
            visibleDelete.value = false;
        };

    const openDialog = () => {
      visibleDelete.value = true;
    };


    return {
      deleteSuccessfull,
      visibleDelete,
      openDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.button-delete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: max-content;
  gap: 8px;
  min-width: 103px;
  cursor: pointer;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2F80ED;
}


::v-deep(button) {
  max-width: 104px;
  height: 40px;
}
</style>
  