<template>
    <div class="informations">
        <h1>Profissões e tratamentos</h1>
        <div class="desc">   
            <ListProfessions v-if="obj.professionsList" :professionsList="obj.professionsList" />
            <ListSpecialities v-if="obj.specialitiesList && obj.specialitiesList.length > 0" :specialitiesList="obj.specialitiesList" />
            <ListTreatedDiseases v-if="obj.diseasesList && obj.diseasesList.length > 0" :diseasesList="obj.diseasesList" />
        </div> 
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";
import ListProfessions from "./ListProfessions";
import ListSpecialities from "./ListSpecialities";
import ListTreatedDiseases from "./ListTreatedDiseases";

export default {
    props: ["obj"],
    components: {
        ListProfessions,
        ListSpecialities,
        ListTreatedDiseases,
    },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.informations {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background: white;
    margin-top: -4px;
    border-radius: 0 0 8px 8px;
    padding: 12px;
    padding-bottom: 0px;
    padding-top: 16px;
    z-index: 1;
    gap: 8px;

    .desc {
        display: flex;
        flex-direction: column;
        gap: 8px;
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
        border-radius: 8px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #828282;
        padding: 16px 12px;

        p {
            max-width: 100%;
            overflow: hidden; // Removendo barra de rolagem
            text-overflow: ellipsis; // Adicionando "..." ao final
            display: -webkit-box;
            -webkit-line-clamp: 2; // Quantidade de linhas
            -webkit-box-orient: vertical;
        }

    }
}

h1{
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #BDBDBD;
}
</style>