<template>
    <div class="left">
        <div class="flex align-items-center gap-10" style="min-width: 135px;">
            <img class="avatar-default" :style="{ border: '1px dashed rgb(189, 189, 189, .6)' }"
                src="../../../../assets/icons/upload-default.png" alt="" v-if="!obj.pictureurl" />
            <img class="p-avatar" :src="getAvatar" alt="" :style="{ width: '40px', height: '40px' }" v-else />
            <b> {{ name }} </b>
            <span class="description"> {{ description }} </span>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
    props: ["obj"],
    // components: {
    // },
    setup(props, ctx) {

        const getAvatar = computed(() => {
            return process.env.VUE_APP_BUCKET_URL + '/' + props.obj.pictureurl
        })

        const name = computed(() => {
            return props.obj.firstname + " " + props.obj.lastname
        })

        const description = computed(() => {
            if (!props.obj.description) {
                return ""
            }   
            return props.obj.description
        })

        return {
            getAvatar,
            name,
            description,
        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}

b {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}

.description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #BDBDBD;
}

.left {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 32px;

    .avatar-default {
        width: 40px;
        height: 40px;
        border-radius: 10px;
    }
}
</style>