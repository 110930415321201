<template>
  <div class="p-3 pt-0">

    <div class="limit p-3"> 
      <h1 class="title-h1">Parcerias</h1>
      <div class="container-service mt-4" style="margin-bottom: 28px;">

        <div class="left">
          <Button class="btn principal" @click="add">Adicionar</Button>
          <!-- <div class="btns-frequency">
            <Button class="btn gap-8" v-for="item in btnFilter" :key="item" :class="isFilter(item.label)">
              {{ item.label }}
              <img src="../../assets/icons/expanded-menu.png" alt="expandir menu" />
            </Button>
          </div> -->
        </div>
        
        <!-- Campo de busca -->
        <span class="container-search p-input-icon-left">
          <i class="pi pi-search" v-tooltip.left="'Buscar'" v-if="!isLoading" />
          <i class="pi pi-spin pi-spinner" v-else />
          <InputText class="search__input text-center" type="text" :disabled="isLoading" :class="isLoading && 'cursor-not-allowed'" placeholder="Pesquisar" v-model="searchInput"
              @change="onChangeSearch($event.target.value)" />
        </span>

      </div>

      <div class="content">
        <NoRegister v-if="!isLoading && dataPartners.length === 0" />
        <List :obj="dataPartners"  v-if="!isLoading" @successfull="refreshList" @reload="reload"/>
        <Loader v-if="isLoading"/>
      </div>

    </div>

    <DialogAdd v-model:visible="viewAdd" @addPartnerSuccess="refreshList" v-if="viewAdd" />

  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import actions from "./actions";
import DialogAdd from "./Dialogs/DialogAdd/DialogAdd.vue";
import List from "./List";
import Loader from "./Loader";
import NoRegister from "./List/NoRegister";
import {
  SearchListPartnershipsRequest
} from "../core/grpc/generated/professionalProfile_grpc_web_pb";
import profileService from "../core/services/professionalProfileService";
import ListPartnership from "./actions.js"


export default {
  components: {
    List,
    DialogAdd,
    Loader,
    NoRegister
  },
  setup(props, ctx) {

    const dataPartners = ref([]);
    const viewAdd = ref(false);
    // const list = ref([])
    // const viewUnlink = ref(false);
    const isLoading = ref(true);
    const searchInput = ref("");
    const filter = ref("");
    const btnFilter = [
      { label: "Profissão" },
      { label: "Especialidade" },
      { label: "Local" },
    ];

    const add = () => {
      viewAdd.value = true;
    };

    const isFilter = (item) => {
      if (item !== filter.value) {
        return "inative";
      }
    };

    // const changeFilter = (item) => {
    //     filter.value = item.label;
    //     if(searchInput.value.length < 3){
    //       ListPartnership(item.label)
    //     }else{
    //         SearchListPartnerships(searchInput.value);
    //     }
    // };

    const ListPartnership = async () => {
      isLoading.value = true;
      try {
        const res = await actions.ListPartnership();
        const { success, partnersList } = res;

        if (success && partnersList && partnersList.length) {
          dataPartners.value = partnersList;
        }
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    };

    const SearchListPartnerships = async word => {
      isLoading.value = true
      if (word.length > 0) {
          try {
            const req = new SearchListPartnershipsRequest();
            req.setWord(word);

            const res = await profileService.searchListPartnerships(req);
            const { success } = res;

              if (success) {
                console.log(res);
                dataPartners.value = res.partnersList;
              }

          } catch (error) {
              console.log(error)
          }
      } else {
        ListPartnership();
      }
      isLoading.value = false
    };

    const refreshList = () => {
      ListPartnership();
    };

    const onChangeSearch = word => {
      SearchListPartnerships(word)
    }

    const reload = () => {
      ListPartnership(filter.value);
    }

    onMounted(async () => {
      isLoading.value = true;
      const res = await actions.ListPartnership();
      console.log("ListPartnership", res)
      if(res?.success && res?.partnersList?.length){
        dataPartners.value = res.partnersList
      }

      isLoading.value = false;
    });

    return {
      viewAdd,
      // viewUnlink,
      add,
      filter,
      btnFilter,
      isFilter,
      // changeFilter,
      isLoading,
      searchInput,
      dataPartners,
      // openDialogUnlink,
      onChangeSearch,
      ListPartnership,
      // list,
      reload,
      refreshList,
    }
  }
}
</script>


<style lang="scss" scoped>
.container-service {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 36px !important;

  .principal {
    display: flex;
    justify-content: center;
    width: 176px;
    background: linear-gradient(171.55deg, #FF6A33 60.76%, #FF4500 99.68%);
  }

  input {
    width: 200px;
  }

  .search__input {
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
  }

  .left {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 278px);
    gap: 10px;

    @media (max-width: 970px) {
      width: 100%;
      margin-bottom: 10px;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
}

.btn {
  width: max-content;
  height: 40px;
}

.btns-frequency {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  .inative {
    background: #fff !important;
    border: 1px solid #fff;
    color: #828282 !important;
  }

  .btn:nth-child(1) {
    background: #FF6A33;
    padding: 0px 32px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .btn:nth-child(2) {
    background: #FF6A33;
    padding: 0px 32px;
    border-radius: 0px;
  }

  .btn:nth-child(3) {
    background: #FF6A33;
    padding: 0px 32px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  @media (max-width: 995px) {
    justify-content: flex-start;
  }

  @media (max-width: 456px) {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}

::v-deep(input) {
  height: 40px;
  border-radius: 8px;
  padding: 0 19px;
  color: #bdbdbd;
  border: 1px solid white;
  outline: none;

  &::-webkit-input-placeholder {
    color: #bdbdbd;
  }

  &:focus {
    border-color: #ff6a33;
  }
}

::v-deep(.container-search) {
  width: 260px;

  input {
    width: 100% !important;
  }
}

::v-deep(.pi, .pi-search) {
  color: #BDBDBD !important;
}

::v-deep(.p-input-icon-right) {
  cursor: pointer;
  vertical-align: middle;
}
</style>