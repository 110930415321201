<template>
  <div class="button-delete">
    <div class="flex cursor-pointer align-items-center gap-6 edit-data" 
      v-tooltip.top="'Ver perfil'"
      @click="openProfile">
      Perfil Profissional
      <img src="../../../../assets/icons/profissionalicon.svg" />
    </div>
  </div>
</template>
  
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  props: ["obj"],
  emits: [],
  components: {},
  setup(props) {

    const router = useRouter();

    const openProfile = () => {
      // Chamar o método ListPartnerships para obter as informações do médico parceiro
      // e, em seguida, navegar para a tela PerfilProfissionalParceiro

      // Exemplo de chamada do método ListPartnerships
      ListPartnerships().then((dadosMedicoParceiro) => {
        router.push({
          name: "PerfilProfissionalParceiro",
          params: {
            id: obj.id,
          },
          // Ou, usando a propriedade "state" do roteador:
          // state: {
          //   medicoParceiro: dadosMedicoParceiro,
          // },
        });
      });
    };

    return {
      openProfile,
    };
  },
};
</script>

<style lang="scss" scoped>
.button-delete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: max-content;
  gap: 8px;
  min-width: 103px;
  cursor: pointer;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
}


::v-deep(button) {
  max-width: 104px;
  height: 40px;
}
</style>
  