import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-367b4b66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "flex flex-column w-6 gap-16" }
const _hoisted_3 = { class: "flex flex-column w-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Biography = _resolveComponent("Biography")!
  const _component_ProfessionsAndTreatments = _resolveComponent("ProfessionsAndTreatments")!
  const _component_ServiceLocations = _resolveComponent("ServiceLocations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Biography, { obj: $props.obj }, null, 8, ["obj"]),
      _createVNode(_component_ProfessionsAndTreatments, { obj: $props.obj }, null, 8, ["obj"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      ($props.obj.servicelocationsList)
        ? (_openBlock(), _createBlock(_component_ServiceLocations, {
            key: 0,
            servicelocationsList: $props.obj.servicelocationsList
          }, null, 8, ["servicelocationsList"]))
        : _createCommentVNode("", true)
    ])
  ]))
}