<template>
    <div class="right">
        <div class="flex align-items-center gap-24">
            <SeePartnerProfile />
            <DeletePartnership 
                :obj="obj"
                @successfull="$emit('successfull', true)"
            />
        </div>
        <div class="flex align-items-center gap-14">
            <img class="pointer" src="../../../../assets/icons/expanded-table.png" alt="expanded item" @click="$emit('toggleItem', $event)" v-if="!visibleItem" />
            <img class="pointer" src="../../../../assets/icons/down-item.png" alt="expanded item" @click="$emit('toggleItem', $event)" v-else />
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import DeletePartnership from "./DeletePartnership";
import SeePartnerProfile from "./SeePartnerProfile";

export default {
    props: ["obj", "toggleItem", "visibleItem"],
    emits: ["toggleItem", "successfull"],
    components:{
        DeletePartnership,
        SeePartnerProfile,
    },    
    setup(props, { emit }) {

        // const visibleDelete = ref(false);

        // const deleteSuccessfull = () => {
        //     emit("successfull", true);
        // }

        // const reload = () => {
        //     emit("reload");
        // }

        return {
            // visibleDelete,
            // deleteSuccessfull,
            // reload
        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}
b{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}
.gap40{
    gap: 40px;
}
.pointer{
    cursor: pointer;
}

.right {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 16px;
    padding-right: 20px;
    .img-profile{
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin-right: 12px;
    }
}
</style>