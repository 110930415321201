<template>
    <div class="list-of-professions">
        <div class="first-card">Doenças tratadas</div>
        <div v-for="(diseases, index) in diseasesList" :key="index">
            <div class="item" v-if="index < 3">
                testte
            </div>
            <div class="more" v-if="index === 3">
                ...
            </div>
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    props: ["diseasesList"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.list-of-professions {
    display: flex;
    width: 100%;
    background: white;
    gap: 8px;

    .first-card {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #BDBDBD;
        padding: 6px 12px;

        border: 1px solid #E0E0E0;
        border-radius: 4px;
        height: 28px;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #828282;

        padding: 6px 12px;
        background: #F2F2F2;
        border-radius: 4px;
        height: 28px;
    }

    .more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #828282;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 6px 12px;
        height: 28px;
        width: max-content;
    }
}
</style>