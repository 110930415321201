<template>
    <div class="list-of-professions">
        <div class="first-card">Profissões</div>
        <div v-for="(profession, index) in professionsList" :key="index">
            <div class="item" v-if="index < 3">
                {{ profession.occupation }}
                <img 
                    src="../../../../../assets/icons/approve_profission.png" 
                    alt="" 
                    v-tooltip.top="'Professional verificado'"
                    v-if="profession.occupation === 'Médico(a)'" 
                />
                <img 
                    src="../../../../../assets/icons/alert_profission.png" 
                    alt="" 
                    v-tooltip.top="'Professional ainda não verificado pela Ommed'"
                    v-else
                />
            </div>
            <div class="more" v-if="index === 3">
                ...
            </div>
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    props: ["professionsList"],
    // components: {
    //     A,
    // },
    setup(props) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.list-of-professions {
    display: flex;
    width: 100%;
    background: white;
    gap: 8px;

    .first-card {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #BDBDBD;
        padding: 6px 12px;

        border: 1px solid #E0E0E0;
        border-radius: 4px;
        height: 28px;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
        gap: 4px;
        height: 28px;

        padding: 6px 12px;
        background: #F2F2F2;
        background: #43C6AC;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #faf8f8, #fcf6f3);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #faf8f8, #fcf6f3);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        border-radius: 4px;
    }

    .more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #828282;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 6px 12px;
        height: 28px;
    }
}
::v-deep(.p-tooltip-arrow){
    width: max-content;
}
</style>