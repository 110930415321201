
import { onMounted, ref } from "vue";
import Biography from "./Biography.vue";
import ServiceLocations from "./ServiceLocations.vue";
import ProfessionsAndTreatments from "./ProfessionsAndTreatments/index.vue";

export default {
    props: ["obj"],
    components: {
        Biography,
        ServiceLocations,
        ProfessionsAndTreatments,
    },
    setup(props) {


        return {
            // bool,
        }
    },
};
